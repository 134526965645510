import * as React from "react"
// import { Link } from "gatsby"
// import { Helmet } from "debug"
// import { Heading } from "theme-ui"
import { graphql } from 'gatsby'

import Seo from "../../components/seo"
import AnimationWrapper from "../../components/animationWrapper"
import ContactSection from "../../components/contactSection"
import Container from "../../components/container"
import Header from "../../components/header"
import IconSection from "../../components/iconSection"
import TextSection from "../../components/textSection"
import TileSection from "../../components/tileSection"
import Title from "../../components/title"
import Layout from "../../components/layout"

import "./produkty.css"

const ProduktyPage = ( {data, location} ) => {

  // set meta description values
  const metaDescription = data.page.frontmatter.meta_description || `Czamaninek ➤ ${data.page.frontmatter.title} ✔️ Ekologiczne materiały budowlane ✔️ Polski producent ⭐ Sprawdź!`;

  return (
    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} description={metaDescription} />

      <AnimationWrapper>
        <Header data={data.page.frontmatter.header} slug={data.page.frontmatter.slug} />
      </AnimationWrapper>

      {data.page.frontmatter.text_section_main.active &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_section_main} />
          </AnimationWrapper>
        </Container>
      }

      <section id="produkty-tiles-section" className="page-section">
        <Container>
          <AnimationWrapper animationType="fadeIn">
            <TileSection data={data.tiles} location="produkty" />
          </AnimationWrapper>
        </Container>
      </section>

      <section id="produkty-aku-multi-section" className="page-section">
        <Container>
          <AnimationWrapper animationType="fadeIn">
            <Title title={data.page.frontmatter.icons_section_title} subtitle="Kompleksowy system budowy domu z keramzytu"></Title>
            <IconSection data={data.page.frontmatter} location="homepage-keramzyt" />
          </AnimationWrapper>
        </Container>
      </section>

      <section id="info-tiles-section" className="page-section">
        <Container>
          <AnimationWrapper animationType="fadeIn">
            <TileSection data={data.infoTiles} location="info" />
          </AnimationWrapper>
        </Container>
      </section>

      {data.page.frontmatter.text_section_bottom.active &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_section_bottom} />
          </AnimationWrapper>
        </Container>
      }

      <section id="contact-data" className="page-section">
        <ContactSection data={data.contactSection.frontmatter.contact_section} />
      </section>

    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    page: mdx(frontmatter: { slug: { eq: "produkty" }, type: { eq: "page" } }) {
      frontmatter{
        title
        meta_description
        header{
          title
          breadcrumbs {
            title
            url
          }
          overlay
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        text_section_main{
          active
          title
          title_visible
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          align
          text
          button_text
          button_url
          button_style
        }
        icons_section_title
        icons{
          title
          text
          image{
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        text_section_bottom{
          active
          title
          title_visible
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          align
          text
          button_text
          button_url
          button_style
        }
      }
    }
    tiles: allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {internal: {contentFilePath: {regex: "/(tiles/produkty/)/"}}}
    ){
      nodes {
        frontmatter {
          title
          date(formatString: "MMMM D, YYYY")
          title_color
          title_bg_color
          type
          active
          url
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit:COVER}
                webpOptions: {quality:95}
              )
            }
          }
        }
        id
      }
    }
    infoTiles: allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {internal: {contentFilePath: {regex: "/(tiles/info/)/"}}}
    ){
      nodes {
        frontmatter {
          title
          date(formatString: "MMMM D, YYYY")
          title_color
          title_bg_color
          type
          active
          url
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit:COVER}
                webpOptions: {quality:95}
              )
            }
          }
        }
        id
      }
    }
    contactSection: mdx(
      frontmatter: {slug: {eq: "contact-section"}, type: {eq: "global"}}
    ){
      frontmatter{
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          image_object_fit
          image_object_position
        }
      }
    }
  }
`
export default ProduktyPage